import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Spinner from './components/Spinner.js';

const routePaths = [
      {
        path: '/',
        childrens: [
          {
            //index
            isIndex: true,
            content: React.lazy(() => import('./pages/Login'))
          },
          {
            path: 'login',
            content: React.lazy(() => import('./pages/Login'))
          }
        ]
      },
      {
        path: '/form-builder/*',
        content: React.lazy(() => import('./pages/Layout')),
        childrens: [
          {
            path: 'list',
            isIndex: true,
            content: React.lazy(() => import('./pages/formBuilder/List'))
          },
          {
            path: 'create',
            content: React.lazy(() => import('./pages/formBuilder/FormBuilder'))
          },
          {
            path: 'edit',
            content: React.lazy(() => import('./pages/formBuilder/Edit'))
          },
          {
            path: 'list-entries-by-form/:formId',
            content: React.lazy(() => import('./pages/formBuilder/ListEntriesByForm'))
          },
          {
            path: 'edit-entry-admin/:formId/:entryUuid/:statusCode',
            content: React.lazy(() => import('./pages/generateForms/GenerateFormAdmin'))
          },
        ]
      }, 
      {
        path: '/generate-form/:formId/:entryUuid?',
        content: React.lazy(() => import('./pages/generateForms/GenerateForm'))
      }
    ];

export function getRoutePaths(){
  return routePaths;
};

function Load(Page){
  const Loader = ()=><div className="d-flex justify-content-center mt-5">
    <Spinner/>
  </div>;
  return <React.Suspense fallback={<Loader/>}>
    <Page/>
  </React.Suspense>;
}
export default function RoutesComponent(props){
  function customRoute(routePath, index){
    return <Route
      key={index}
      { ...(routePath.index === true ? { index: true } : {}) }
      path={routePath.path ?? '' }
      {...(routePath.content) ? { element: Load(routePath.content) } : {} }
    >
      { Array.isArray(routePath.childrens) && routePath.childrens.map((routeChildren, index)=>customRoute(routeChildren, index)) }
    </Route>;
  }

  return <>
    <BrowserRouter>
      <Routes>
        {routePaths.map((routePath, index)=>{
          return customRoute(routePath, index);
        })}
      </Routes>
    </BrowserRouter>
  </>;
}
